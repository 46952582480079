import beachOne from './Assets/beach_1.jpg'
import beachTwo from './Assets/beach_2.jpg'
import beachThreeLarge from './Assets/beach_3-large.jpg'
import beachThree from './Assets/beach_3.jpg'
import beachFour from './Assets/beach_4.jpg'
import beachFive from './Assets/beach_5.jpg'
import brick from './Assets/brick.jpg'
import dinner from './Assets/dinner.jpg'
import jacksonhole from './Assets/jacksonhole.jpg'
import matterhorn from './Assets/matterhorn.jpg'
import milian from './Assets/milian.jpg'
import netflix from './Assets/netflix.png'
import bar1 from './Assets/bar_1.jpg'
import bigSky1 from './Assets/big_sky_1.jpg'
import bigSky2 from './Assets/big_sky_2.jpg'
import covidLife from './Assets/covid_life.jpg'
import covidLife2 from './Assets/covid_life_2.jpg'
import dolphins1 from './Assets/dolphins_1.jpg'
import france1 from './Assets/france_1.jpg'
import jacksonhole2 from './Assets/jacksonhole_2.jpg'
import montreal from './Assets/montreal.jpg'
import wedding1 from './Assets/wedding_1.jpg'
import wedding2 from './Assets/wedding_2.jpg'
import wedding3 from './Assets/wedding_3.jpg'
import zermont from './Assets/zermont.jpg'

import pic0025 from './Assets/0025.jpg'
import pic0037 from './Assets/0037.jpg'
import pic0038 from './Assets/0038.jpg'
import pic0071 from './Assets/0071.jpg'
import pic0073 from './Assets/0073.jpg'
import pic0074 from './Assets/0074.jpg'
import pic0078 from './Assets/0078.jpg'
import pic0090 from './Assets/0090.jpg'
import pic0141 from './Assets/0141.jpg'
import pic0144 from './Assets/0144.jpg'
import pic0162 from './Assets/0162.jpg'
import pic0186 from './Assets/0186.jpg'
import pic0188 from './Assets/0188.jpg'
import pic0189 from './Assets/0189.jpg'
import pic0195 from './Assets/0195.jpg'
import pic0236 from './Assets/0236.jpg'
import pic0237 from './Assets/0237.jpg'
import pic0243 from './Assets/0243.jpg'
import img4214Original from './Assets/IMG_4214_Original.jpg'
import img4274 from './Assets/IMG_4274.jpg'
import img4303Original from './Assets/IMG_4303_Original.jpg'
import img4411 from './Assets/IMG_4411.jpg'
import img4422 from './Assets/IMG_4422.jpg'
import img4426 from './Assets/IMG_4426.jpg'
import img6807 from './Assets/IMG_6807.jpg'
import img7386 from './Assets/IMG_7386.jpg'
import iraLippkeStudiosLinaShteynBarDivEng004 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-004.jpg'
import iraLippkeStudiosLinaShteynBarDivEng008 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-008.jpg'
import iraLippkeStudiosLinaShteynBarDivEng011 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-011.jpg'
import iraLippkeStudiosLinaShteynBarDivEng012 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-012.jpg'
import iraLippkeStudiosLinaShteynBarDivEng015 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-015.jpg'
import iraLippkeStudiosLinaShteynBarDivEng025 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-025.jpg'
import iraLippkeStudiosLinaShteynBarDivEng030 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-030.jpg'
import iraLippkeStudiosLinaShteynBarDivEng031 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-031.jpg'
import iraLippkeStudiosLinaShteynBarDivEng038 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-038.jpg'
import iraLippkeStudiosLinaShteynBarDivEng039 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-039.jpg'
import iraLippkeStudiosLinaShteynBarDivEng043 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-043.jpg'
import iraLippkeStudiosLinaShteynBarDivEng044 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-044.jpg'
import iraLippkeStudiosLinaShteynBarDivEng049 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-049.jpg'
import iraLippkeStudiosLinaShteynBarDivEng052 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-052.jpg'
import iraLippkeStudiosLinaShteynBarDivEng053 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-053.jpg'
import iraLippkeStudiosLinaShteynBarDivEng054 from './Assets/IraLippkeStudios_Lina_Shteyn_BarDivEng-054.jpg'

export const photos = {
  beachOne: { url: beachOne, description: 'ring on the beach' },
  beachTwo: { url: beachTwo, description: 'close up' },
  beachThreeLarge: { url: beachThreeLarge, description: 'piggy back large' },
  beachThree: { url: beachThree, description: 'piggy back' },
  beachFour: { url: beachFour, description: 'hand holding walk' },
  beachFive: { url: beachFive, description: 'pointing' },
  brick: { url: brick, description: 'scaffolding' },
  dinner: { url: dinner, description: 'engagement dinner' },
  jacksonhole: { url: jacksonhole, description: 'jackson hole' },
  matterhorn: { url: matterhorn, description: 'big mountain' },
  milian: { url: milian, description: 'sky daddy house' },
  netflix: { url: netflix, description: 'we famous' },
  bar1: { url: bar1, description: 'bar1' },
  bigSky1: { url: bigSky1, description: 'bigSky1' },
  bigSky2: { url: bigSky2, description: 'bigSky2' },
  covidLife: { url: covidLife, description: 'covidLife' },
  covidLife2: { url: covidLife2, description: 'covidLife2' },
  dolphins1: { url: dolphins1, description: 'dolphins1' },
  france1: { url: france1, description: 'france1' },
  jacksonhole2: { url: jacksonhole2, description: 'jacksonhole2' },
  montreal: { url: montreal, description: 'montreal' },
  wedding1: { url: wedding1, description: 'wedding1' },
  wedding2: { url: wedding2, description: 'wedding2' },
  wedding3: { url: wedding3, description: 'wedding3' },
  zermont: { url: zermont, description: 'zermont' },
  pic0025: { url: pic0025, description: 'pic0025' },
  pic0037: { url: pic0037, description: 'pic0037' },
  pic0038: { url: pic0038, description: 'pic0038' },
  pic0071: { url: pic0071, description: 'pic0071' },
  pic0073: { url: pic0073, description: 'pic0073' },
  pic0074: { url: pic0074, description: 'pic0074' },
  pic0078: { url: pic0078, description: 'pic0078' },
  pic0090: { url: pic0090, description: 'pic0090' },
  pic0141: { url: pic0141, description: 'pic0141' },
  pic0144: { url: pic0144, description: 'pic0144' },
  pic0162: { url: pic0162, description: 'pic0162' },
  pic0186: { url: pic0186, description: 'pic0186' },
  pic0188: { url: pic0188, description: 'pic0188' },
  pic0189: { url: pic0189, description: 'pic0189' },
  pic0195: { url: pic0195, description: 'pic0195' },
  pic0236: { url: pic0236, description: 'pic0236' },
  pic0237: { url: pic0237, description: 'pic0237' },
  pic0243: { url: pic0243, description: 'pic0243' },
  img4214Original: { url: img4214Original, description: 'img4214Original' },
  img4274: { url: img4274, description: 'img4274' },
  img4303Original: { url: img4303Original, description: 'img4303Original' },
  img4411: { url: img4411, description: 'img4411' },
  img4422: { url: img4422, description: 'img4422' },
  img4426: { url: img4426, description: 'img4426' },
  img6807: { url: img6807, description: 'img6807' },
  img7386: { url: img7386, description: 'img7386' },
  iraLippkeStudiosLinaShteynBarDivEng004: {
    url: iraLippkeStudiosLinaShteynBarDivEng004,
    description: 'iraLippkeStudiosLinaShteynBarDivEng004'
  },
  iraLippkeStudiosLinaShteynBarDivEng008: {
    url: iraLippkeStudiosLinaShteynBarDivEng008,
    description: 'iraLippkeStudiosLinaShteynBarDivEng008'
  },
  iraLippkeStudiosLinaShteynBarDivEng011: {
    url: iraLippkeStudiosLinaShteynBarDivEng011,
    description: 'iraLippkeStudiosLinaShteynBarDivEng011'
  },
  iraLippkeStudiosLinaShteynBarDivEng012: {
    url: iraLippkeStudiosLinaShteynBarDivEng012,
    description: 'iraLippkeStudiosLinaShteynBarDivEng012'
  },
  iraLippkeStudiosLinaShteynBarDivEng015: {
    url: iraLippkeStudiosLinaShteynBarDivEng015,
    description: 'iraLippkeStudiosLinaShteynBarDivEng015'
  },
  iraLippkeStudiosLinaShteynBarDivEng025: {
    url: iraLippkeStudiosLinaShteynBarDivEng025,
    description: 'iraLippkeStudiosLinaShteynBarDivEng025'
  },
  iraLippkeStudiosLinaShteynBarDivEng030: {
    url: iraLippkeStudiosLinaShteynBarDivEng030,
    description: 'iraLippkeStudiosLinaShteynBarDivEng030'
  },
  iraLippkeStudiosLinaShteynBarDivEng031: {
    url: iraLippkeStudiosLinaShteynBarDivEng031,
    description: 'iraLippkeStudiosLinaShteynBarDivEng031'
  },
  iraLippkeStudiosLinaShteynBarDivEng038: {
    url: iraLippkeStudiosLinaShteynBarDivEng038,
    description: 'iraLippkeStudiosLinaShteynBarDivEng038'
  },
  iraLippkeStudiosLinaShteynBarDivEng039: {
    url: iraLippkeStudiosLinaShteynBarDivEng039,
    description: 'iraLippkeStudiosLinaShteynBarDivEng039'
  },
  iraLippkeStudiosLinaShteynBarDivEng043: {
    url: iraLippkeStudiosLinaShteynBarDivEng043,
    description: 'iraLippkeStudiosLinaShteynBarDivEng043'
  },
  iraLippkeStudiosLinaShteynBarDivEng044: {
    url: iraLippkeStudiosLinaShteynBarDivEng044,
    description: 'iraLippkeStudiosLinaShteynBarDivEng044'
  },
  iraLippkeStudiosLinaShteynBarDivEng049: {
    url: iraLippkeStudiosLinaShteynBarDivEng049,
    description: 'iraLippkeStudiosLinaShteynBarDivEng049'
  },
  iraLippkeStudiosLinaShteynBarDivEng052: {
    url: iraLippkeStudiosLinaShteynBarDivEng052,
    description: 'iraLippkeStudiosLinaShteynBarDivEng052'
  },
  iraLippkeStudiosLinaShteynBarDivEng053: {
    url: iraLippkeStudiosLinaShteynBarDivEng053,
    description: 'iraLippkeStudiosLinaShteynBarDivEng053'
  },
  iraLippkeStudiosLinaShteynBarDivEng054: {
    url: iraLippkeStudiosLinaShteynBarDivEng054,
    description: 'iraLippkeStudiosLinaShteynBarDivEng054'
  }
}

export const coverPhotos = [photos.brick, photos.pic0162, photos.beachOne]

export const beachPhotos = [
  photos.beachTwo,
  photos.beachThreeLarge,
  photos.beachThree,
  photos.beachFour,
  photos.beachFive
]

export const otherPhotos = [
  photos.dinner,
  photos.jacksonhole,
  photos.matterhorn,
  photos.milian,
  photos.netflix,
  photos.bar1,
  photos.bigSky1,
  photos.bigSky2,
  photos.covidLife,
  photos.covidLife2,
  photos.dolphins1,
  photos.france1,
  photos.jacksonhole2,
  photos.montreal,
  photos.wedding1,
  photos.wedding2,
  photos.wedding3,
  photos.zermont,
  photos.pic0025,
  photos.pic0037,
  photos.pic0038,
  photos.pic0071,
  photos.pic0073,
  photos.pic0074,
  photos.pic0078,
  photos.pic0090,
  photos.pic0141,
  photos.pic0144,
  photos.pic0186,
  photos.pic0188,
  photos.pic0189,
  photos.pic0195,
  photos.pic0236,
  photos.pic0237,
  photos.pic0243,
  photos.img4214Original,
  photos.img4274,
  photos.img4303Original,
  photos.img4411,
  photos.img4422,
  photos.img4426,
  photos.img6807,
  photos.img7386,
  photos.iraLippkeStudiosLinaShteynBarDivEng004,
  photos.iraLippkeStudiosLinaShteynBarDivEng008,
  photos.iraLippkeStudiosLinaShteynBarDivEng011,
  photos.iraLippkeStudiosLinaShteynBarDivEng012,
  photos.iraLippkeStudiosLinaShteynBarDivEng015,
  photos.iraLippkeStudiosLinaShteynBarDivEng025,
  photos.iraLippkeStudiosLinaShteynBarDivEng030,
  photos.iraLippkeStudiosLinaShteynBarDivEng031,
  photos.iraLippkeStudiosLinaShteynBarDivEng038,
  photos.iraLippkeStudiosLinaShteynBarDivEng039,
  photos.iraLippkeStudiosLinaShteynBarDivEng043,
  photos.iraLippkeStudiosLinaShteynBarDivEng044,
  photos.iraLippkeStudiosLinaShteynBarDivEng049,
  photos.iraLippkeStudiosLinaShteynBarDivEng052,
  photos.iraLippkeStudiosLinaShteynBarDivEng053,
  photos.iraLippkeStudiosLinaShteynBarDivEng054
]

export const allExceptCover = [...beachPhotos, ...otherPhotos]
